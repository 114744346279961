
import { computed, defineComponent, onMounted, reactive } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import draggable from "vuedraggable";
import { getFilePath } from "@/core/utils/file";
import { deleteFacilityLocationImages } from "@/core/services/api/file";
import Swal from "sweetalert2/dist/sweetalert2.js";
import PreviewLocationImg from "./PreviewLocationImg.vue";
import { Modal } from "bootstrap";
import { getSpacesByManager } from "@/core/services/api/spaces";
import { useRouter } from "vue-router";
import { updateSpace } from "@/core/services/api/spaces";
import { getFacility, updateFacility } from "@/core/services/api/facilities";

export default defineComponent({
  components: {
    draggable,
    PreviewLocationImg,
  },
  emits: ["cancelPreview", "uploadFile"],
  props: ["facilityGuid"],
  setup(props) {
    const facilityData = reactive({
      type: "Facility",
      mode: "Standard",
      status: "Public",
      information: [
        {
          icon: "string",
          title: "string",
          content: "string",
        },
      ],
      theme: {
        coverPhoto: "string",
        images: ["string"],
        locationImages: ["string"],
      },
      setting: {
        availableDays: 0,
        invitationNumber: 0,
        minuteToEnter: 0,
        minuteToCancel: 0,
        minuteToExtend: 0,
        minuteToCheckOut: 0,
        autoRelease: true,
        delayCaptureInDays: 0,
        canDoorAccess: true,
        canIotFetch: true,
        canDistribute: true,
        canInvite: true,
        canUserCheckOut: true,
        canUserFeedback: true,
      },
      guid: "string",
      space: "string",
      id: "string",
      name: "string",
      category: "string",
      createTime: "2021-11-10T01:35:14.477Z",
      description: "string",
      openingHoursDescription: "string",
      location: "string",
      quota: 0,
      displayOrder: 0,
      usageNotice: "string",
      priceTag: "string",
    });
    const currentSpace = localstorage.getCurrentSpaces()?.space;
    const router = useRouter();
    let previewFile: any = reactive([]);
    let previewModal1;
    let files = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const facilityThemeImgs: any = reactive([]);
    const setFacilityData = async () => {
      let response: any = await getFacility(props.facilityGuid);
      Object.assign(facilityData, response.facility);
    };
    const setfacilityThemeImgs = () => {
      let themeImgs: any = facilityData.theme.locationImages;
      facilityThemeImgs.splice(0, facilityThemeImgs.length, ...themeImgs);
    };

    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    });

    const getImgPath = (img) => {
      return getFilePath(img);
    };

    const remove = async (file) => {
      await Swal.fire({
        title: "您確定要刪除圖片嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteFacilityLocationImages(
            currentSpaceGuid!,
            props.facilityGuid,
            file
          ).then(() => {
            Swal.fire("刪除成功!", "", "success").then(() => {
              init();
            });
          });
        }
      });
    };

    const viewData = (e) => {
      previewFile.splice(0, previewFile.length, ...e.target.files);
      if (!filesIsEmpty()) {
        previewModal1.show();
      }
    };

    const cancelPreview = () => {
      previewFile.splice(0);
      let obj: any = document.getElementById(
        "deviceManagement-inputGroupFileFloorPlan"
      );
      obj.value = "";
      previewModal1.hide();
    };

    onMounted(() => {
      previewModal1 = new Modal(
        document.getElementById(
          "deviceManagement-floorPlan-previewLocationImg-modal"
        )
        
      );
    });

    const filesIsEmpty = () => {
      return previewFile.length == 0;
    };

    const init = async () => {
      await setFacilityData();
      setfacilityThemeImgs();
    };
    init();

    return {
      dragOptions,
      getImgPath,
      remove,
      viewData,
      files,
      previewFile,
      cancelPreview,
      filesIsEmpty,
      facilityThemeImgs,
      props,
      init,
    };
  },
});
