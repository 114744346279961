<template>
  <div class="card">
    <div class="card-body p-10">
      <div class="row">
        <div class="col">
          <ul class="nav mb-10">
            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  active
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_1"
                >主題照片</a
              >
            </li>
            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_2"
                >空間平面圖</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content">
        <div class="tab-pane fade active show" id="kt_table_widget_7_tab_1">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <ThemePhoto :facilityGuid="facilityGuid"></ThemePhoto>
          </div>
          <!--end::Table-->
        </div>
        <div class="tab-pane fade" id="kt_table_widget_7_tab_2">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <FloorPlan :facilityGuid="facilityGuid"></FloorPlan>
          </div>
          <!--end::Table-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from "vue";
import ThemePhoto from "./components/ThemePhoto.vue";
import FloorPlan from "./components/FloorPlan.vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: {
    ThemePhoto,
    FloorPlan
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const facilityGuid = route.params.deviceGuid;
    return {
        facilityGuid
    }
  },
});
</script>

<style>
</style>